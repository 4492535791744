import React from 'react'
import ReactDateTime from 'react-datetime'
import * as styles from './form.module.css'
import 'react-datetime/css/react-datetime.css'

const PulseWeekly = (({ scheduleDate, subject, handleSubmit, handleUpdate, disableSubmit }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      if(!disableSubmit){
        handleSubmit(event)
      }
    }}
  >
    <label className={styles[`form__label`]}>
      Email Subject
      <input
        className={styles[`form__input`]}
        type="text"
        name="subject"
        value={subject}
        readOnly
      />
    </label>
    <label className={styles[`form__label`]} htmlFor="schedule-date">
      Schedule Date/Time
      <ReactDateTime
        id="schedule-date"
        value={scheduleDate}
        onChange={handleUpdate}
      />
    </label>
    <input className={styles[`form__button`]} type="submit" value="Schedule Campaign" disabled={disableSubmit} />
  </form>
))

export default PulseWeekly

import React, { Component } from 'react'
import NextPrevButtons from './NextPrevButtons'

class FormTab extends Component {
  render() {
    const {
      props: { nextLabel, prevLabel, lastStep, lastStepLabel },
    } = this

    return (
      <div>
      {this.props.content}
      <NextPrevButtons
        nextStep={this.props.nextStep}
        nextLabel={nextLabel}
        prevStep={this.props.prevStep}
        prevLabel={prevLabel}
        formValid={this.props.formValid}
        nextAction={this.props.nextAction}
        resetLabel={this.props.resetLabel}
        resetForm={this.props.resetForm}
        lastStep={lastStep}
        lastStepLabel={lastStepLabel}
       />
      </div>
    )
  }
}

export default FormTab

import axios from 'axios'
import { Auth } from 'aws-amplify'
import moment from 'moment'
const restPath = process.env.GATSBY_API_URL
// const restPath = 'http://docker.testapi.com'

export const getArticles = (days) => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .get(restPath+'/pulse/getarticles?days='+days)
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const testEmail = (email) => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .post(restPath+'/pulse/email/test',email)
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const listEmailCampaigns = () => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .get(restPath+'/pulse/campaign/list',{})
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const createEmailCampaign = () => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .post(restPath+'/pulse/campaign/create',{})
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const createEmailList = (listData) => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .post(restPath+'/pulse/email-list',listData)
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const getPulseUsers = () => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .get(restPath+'/pulse/users',{})
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const getPulseEmailPreview = (email) => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .post(restPath+'/pulse/email/preview',email)
            .then(function(response) {
              resolve(JSON.parse(response.data))
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const getScheduledCampaigns = () => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          let today = new Date()
          today.setHours(0,0,0,0)
          let params = {
            campaignSent: false,
            scheduleDate: {$gte: today}
          }
          axios
            .post(restPath+'/pulse/campaign/list',params)
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const postNewCampaign = (params) => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .post(restPath+'/pulse/campaign/create',params)
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const deleteCampaign = (campaignId) => {
  return new Promise((resolve, reject) => {
    try{
      Auth.currentSession()
        .then(function(session) {
          axios.defaults.headers.post['Content-Type'] = 'application/json'
          axios.defaults.headers.common['Authorization'] = session.idToken.jwtToken
          axios
            .delete(restPath+'/pulse/campaign/'+campaignId,{})
            .then(function(response) {
              resolve(response.data)
            })
            .catch(function(err) {
              reject(err)
            })
        })
        .catch((err) => {
          reject(err)
        })
    }catch(err){
      reject(err)
    }
  })
}

export const subscribe = (subscribeInfo) => {
  return new Promise((resolve, reject) => {
    try{
      axios({
        method: 'post',
        url: restPath+'/pulse/email/subscribe',
        data: {
          'message': subscribeInfo.message,
          'subject': subscribeInfo.subject,
          'subscriber': {
            'firstName': subscribeInfo.firstName,
            'lastName': subscribeInfo.lastName,
            'email': subscribeInfo.email,
            'language': subscribeInfo.language
          }
        },
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': process.env.GATSBY_FS_API
        }
      })
      .then(function(response) {
        resolve(response.data)
      })
      .catch(function(err) {
        reject(err)
      })
    }catch(err){
      reject(err)
    }
  })
}

export const getNextMonday = () => {
  const dayINeed = 1; // for Monday
  const today = moment().isoWeekday();

  // if we haven't yet passed the day of the week that I need:
  if (today <= dayINeed) {
    // then just give me this week's instance of that day
    return moment().isoWeekday(dayINeed);
  } else {
    // otherwise, give me *next week's* instance of that same day
    return moment().add(1, 'weeks').isoWeekday(dayINeed).set({
      'hour': 5,
      'minute': 0,
      'second': 0
    });
  }
}

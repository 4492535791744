import React, { Component } from 'react'
import Moment from 'moment'
import * as styles from '../../Form/form.module.css'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material'

export default class CampaignsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduledCampaigns: [],
      scheduleCampaignResponse: '',
      showDeleteConfirm: false,
      campaignToDelete: null
    }
  }

  componentDidMount() {

  }

  deleteCampaign = (id) => {
    this.setState({
      showDeleteConfirm: true,
      campaignToDelete: id
    })
  }

  closeDeleteConfirm = () =>{
    this.setState({
      showDeleteConfirm: false,
      campaignToDelete: null
    })
  }

  render() {
    let scheduledCampaignsList = null
    if(this.props.scheduledCampaigns){
      scheduledCampaignsList = this.props.scheduledCampaigns.map(function(item,key){
        return (
          <tr key={key}>
            <td><button onClick={() => this.deleteCampaign(item._id)} className={styles['form__button']}>Delete</button></td>
            <td>{item.subject}</td>
            <td>{item.pulseEmailList.name}</td>
            <td>{Moment(item.scheduleDate)
            .local()
            .format("MM-DD-YYYY hh:mm:ss a")}</td>
          </tr>
        )
      }.bind(this))
    }
    return (
      <div>
        <h2>Scheduled Campaigns in Progress</h2>
        <table>
          <thead>
            <tr><th>&nbsp;</th><th>Subject</th><th>Email List</th><th>Schedule DateTime</th></tr>
          </thead>
          <tbody>
            {scheduledCampaignsList}
          </tbody>
        </table>
        <Dialog
          className="standard-dialog"
          open={this.state.showDeleteConfirm}
          onClose={this.handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Delete Campaign
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to delete this campaign?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button className="underline-button" onClick={this.closeDeleteConfirm}>Cancel</Button>
            <Button variant="contained" onClick={(e) => {
              this.props.deleteCampaign(this.state.campaignToDelete)
              this.setState({
                showDeleteConfirm: false
              })
            }}>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

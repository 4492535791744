import React from 'react'
import PropTypes from 'prop-types'
import { isLoggedIn, isPulseEmailUser } from '../utils/auth'
import { isBrowser } from '../utils/misc'
import { Router, globalHistory } from '@reach/router'

const PulseEmailRoute = ({ component: Component, ...rest }) => {
  if (!isLoggedIn() && isBrowser) {
    // If we’re not logged in, redirect to the home page.
    // navigate(`/login`)
    window.location.href = '/login'
    return null
  }else{
    if(isLoggedIn() && globalHistory.location.pathname.startsWith('/pulse')){
      isPulseEmailUser()
        .then(function(){
        })
        .catch(function(err){
          console.log(globalHistory.location.pathname)
          console.log('i am not a pulse email user')
          if(isBrowser){
            window.location.href = '/'
          }
          return null
        })
    }
  }
  return (
    <Router>
      <Component {...rest} />
    </Router>
  )
}

PulseEmailRoute.propTypes = {
  component: PropTypes.any.isRequired,
}

export default PulseEmailRoute

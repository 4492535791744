import React, { Component } from 'react'
import spinner from '../../img/progress.gif'
import * as styles from './waitloader.module.css'

class WaitLoader extends Component {
  render() {
    let loader = ''
    if (this.props.loading) {
      loader = (
        <div className={styles[`sweet__loading`]}>
          <img src={spinner} width="50" height="50" />
        </div>
      )
    }
    return <div>{loader}</div>
  }
}

export default WaitLoader

import React, { Component } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const editorConfiguration = {
  toolbar: {
      items: [
          'heading',
          '|',
          'bold',
          'italic',
          'underline',
          'link',
          'bulletedList',
          'numberedList',
          'blockQuote',
          '|',
          'imageTextAlternative',
          'undo',
          'redo'
      ]
  },
  image: {
      toolbar: [
          'imageStyle:full',
          'imageStyle:side',
          '|',
          'imageTextAlternative'
      ]
  },
  language: 'en'
}

export default class DigestEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }

  }

  componentDidMount() {
    ClassicEditor.create( {} ).then( editor => {
        console.log( Array.from( editor.ui.componentFactory.names() ) );
    } );
  }

  render() {
    return (
      <CKEditor
        editor={ ClassicEditor }
        config={ editorConfiguration }
        data={this.props.body}
        onInit={
          editor => {

          }
        }
        onChange={
          (event, editor) => {
            const data = editor.getData()
            this.props.onUpdate(data)
          }
        }
      />
    )
  }
}

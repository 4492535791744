import React, { Component } from 'react'
import PropTypes from 'prop-types'
import * as styles from './multistepform.module.css'

class NextPrevButtons extends Component {
  static propTypes = {
    nextStep: PropTypes.func.isRequired,
    prevStep: PropTypes.func.isRequired,
  }

  render() {
    const {
      props: { nextLabel, prevLabel, formValid, resetLabel, lastStepLabel },
    } = this

    let nextButton, prevButton, resetButton, lastStepButton;
    if(nextLabel){
      if(!formValid){
        nextButton = <li><button disabled={true} onClick={this.props.nextStep}>{nextLabel}</button></li>
      }else{
        nextButton = <li><button onClick={this.props.nextStep}>{nextLabel}</button></li>
      }

    }
    if(prevLabel){
      prevButton = <li><button className="previous" onClick={this.props.prevStep}>{prevLabel}</button></li>
    }
    if(resetLabel){
      resetButton = <li><button className="previous" onClick={this.props.resetForm}>{resetLabel}</button></li>
    }
    if(lastStepLabel){
      lastStepButton = <li><button className="previous" onClick={this.props.lastStep}>{lastStepLabel}</button></li>
    }
    return (
      <ul className={styles['multiStepNav']}>
      {prevButton}
      {nextButton}
      {resetButton}
      {lastStepButton}
      </ul>
    )
  }
}

export default NextPrevButtons

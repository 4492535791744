import React from 'react'
import * as styles from './form.module.css'

const PulseDigestTemplate = (({ handleUpdate, selectedTemplate, history }) => (
  <form
    className={styles.form}
  >
    <p className={styles[`form__instructions`]}>
      Please select the template language for this campaign.
    </p>
    <label className={styles[`form__label`]}>
      Template Language
      <select onChange={handleUpdate} className={styles[`form__select`]} name="selectedTemplate" value={selectedTemplate}>
        <option value="pulse-digest">English</option>
        <option value="pulse-digest-es">Spanish</option>
      </select>
    </label>
  </form>
))

export default PulseDigestTemplate

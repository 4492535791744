import React from 'react'
import PulseEmailDigest from '../../components/Pulse/Email-Digest'
import PulseEmailRoute from '../../components/PulseEmailRoute'
import { isBrowser } from '../../utils/misc'
const App = ({ data }) => {
  if (!isBrowser) return null
  return (
    <>
      <PulseEmailRoute
        path="/pulse/email-digest"
        component={PulseEmailDigest}
        fsOnly="false"
        data={data}
      />
    </>
  )
}

export default App

import React, { Component } from 'react'
import Moment from 'moment'
import { TableSelect } from '../../Table'

export default class AvailableArticles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: 0,
      selected: []
    }
  }

  componentDidMount() {

  }

  toggleRow(id) {
    const newSelected = Object.assign({}, this.state.selected)
    newSelected[id] = !this.state.selected[id]
    this.setState({
      selectAll: 2,
      selected: newSelected
    },function(){
      this.props.setSelected(newSelected)
    })
  }

  toggleSelectAll() {
		let newSelected = {};

		if (this.state.selectAll === 0) {
			this.props.articles.forEach(x => {
				newSelected[x.id] = true;
			});
		}

		this.setState({
			selected: newSelected,
			selectAll: this.state.selectAll === 0 ? 1 : 0
		},function(){
      this.props.setSelected(newSelected)
    });
	}

  getSelectedRows = (rows) => {
    this.props.setSelected(rows)
  }

  render() {
    let imageStyle = {
      width:'50px',
      height:'auto',
      margin:'0px'
    }
    const columns = [
      {
        Header: 'Image',
        id: 'image',
        accessor: d => d.item_image,
        Cell: props => <div>
          <img src={props.value} style={imageStyle} alt={props.value} />
          </div>
      },
      {
        Header: 'Title',
        id: 'title',
        accessor: d => d,
        Cell: props => <a href={props.value.url} target="_blank" rel="noopener noreferrer">{props.value.title}</a>
      },
      {
        Header: 'Author',
        id: 'author',
        accessor: d => d.author
      },
      {
        Header: 'Created',
        id: 'created',
        accessor: d => d.created,
        Cell: props => Moment(props.value).local().format("MM-DD-YYYY hh:mm:ss a")
      }
    ]

    // if(this.props.selectable){
    //   columns.unshift({
    //     id: 'checkbox',
    //     accessor: '',
    //     Cell: ({original}) => {
    //       return (
    //         <input
    //           type="checkbox"
    //           className="checkbox"
    //           checked={this.state.selected[original.id] === true}
    //           onChange={() => this.toggleRow(original.id)}
    //         />
    //       )
    //     },
    //     Header: x => {
    //       return (
    //         <input
    //           type="checkbox"
    //           className="checkbox"
    //           checked={this.state.selectAll === 1}
    //           ref={input => {
    //             if (input) {
    //               input.indeterminate = this.state.selectAll === 2;
    //             }
    //           }}
    //           onChange={() => this.toggleSelectAll()}
    //         />
    //       );
    //     },
    //     sortable: false,
    //     width: 45
    //   })
    // }

    return (
      <div>
        <p>Available Published Articles from {this.props.fromDate}</p>

        {this.props.articles.length &&
          <TableSelect
            data={this.props.articles}
            columns={columns}
            defaultPageSize={10}
            minRows={3}
            getSelectedRows={this.getSelectedRows}
          />
        }

      </div>
    );
  }
}

import React from 'react'
import Layout from '../Layout'
import View from '../View'
import Moment from 'moment'
import MultiStepForm from '../MultiStepForm'
import FormTab from '../MultiStepForm/FormTab'
import Form from '../Form/pulse-digest-template-select'
import DigestTest from '../Form/pulse-digest-test'
import {
  getArticles,
  testEmail,
  createEmailList,
  getNextMonday,
  getScheduledCampaigns,
  postNewCampaign,
  deleteCampaign
} from '../../utils/pulse'
import { getCurrentUser } from '../../utils/auth'
import EmailPreview from '../EmailPreview'
import ScheduleForm from '../Form/pulse-weekly-summary-schedule'
import AvailableArticles from '../Pulse/AvailableArticles'
import CampaignsList from '../Pulse/CampaignsList'
import DigestEditor from './DigestEditor'
import * as styles from '../Form/form.module.css'

class PulseEmail extends React.Component {
  constructor(props){
    super(props)
    let user = getCurrentUser()
    let today = Moment()
    let articleDays = 31
    this.state={
      articles: [],
      selectedArticles: [],
      editorBody: '',
      emailListName: '',
      scheduleDate: getNextMonday(),
      emailTo: user.email,
      articleDays: articleDays,
      scheduledCampaigns: [],
      scheduleCampaignResponse: '',
      selectedTemplate: 'pulse-digest',
      testEmailResponse: '',
      articlesFrom: today.subtract(articleDays, 'd').format("dddd, MM/DD/YYYY"),
      errorMessage: ''
    }
  }

  componentDidMount(){
    this.updateScheduledCampaigns()
    getArticles(this.state.articleDays)
    .then((articles)=>{
      this.setState({
        articles: articles
      })
    })
    .catch((err)=>{
      this.setErrorMessage(err)
    })
  }

  updateScheduledCampaigns = () => {
    getScheduledCampaigns()
    .then(function(data){

      if(!data){
        // data = {
        //   campaigns: []
        // }
      }
      this.setState({
        scheduledCampaigns: data.campaigns
      })
    }.bind(this))
    .catch((err)=>{
      this.setErrorMessage(err)
    })
  }

  sendTestEmail = () => {
    let emailPost = {
      'subject': 'Pulse Update | ' + Moment(this.state.scheduleDate).format("MM/DD/YYYY"),
      'toAddress': this.state.emailTo,
      'sendDate': Date.parse(this.state.scheduleDate.format("MM/DD/YYYY h:mm:ss a")),
      'body': this.state.editorBody,
      'template': this.state.selectedTemplate
    }

    testEmail(emailPost)
      .then((data)=>{
        this.setState({
          testEmailResponse: data.message
        })
      })
      .catch((err)=>{
        this.setErrorMessage(err)
      })
  }

  submitNewEmailList = (event) => {
    event.preventDefault()
    createEmailList({name: this.state.emailListName})
    .then(function(data){console.log(data)})
    .catch(function(err){console.log(err)})
  }

  updateEmailList = (event) => {
    this.setState({
      emailListName: event.currentTarget.value
    })
  }

  setErrorMessage = (error) => {
    this.setState({
      errorMessage: error.message
    })
  }

  submitScheduleDate = (event) => {
    event.preventDefault()
    let listId = '5cd092ac55219c797a1bb6cd'
    if(this.state.selectedTemplate === 'pulse-digest-es'){
      listId = '5ce59fef2035f4ab2ca546cb'
    }
    let campaignData = {
      subject: 'Pulse Update | ' + Moment(this.state.scheduleDate).format("MM/DD/YYYY"),
      scheduleDate: Date.parse(Moment(this.state.scheduleDate).format("MM/DD/YYYY h:mm:ss a")),
      isTestCampaign: false,
      pulseEmailList: listId,
      template: this.state.selectedTemplate,
      htmlToSend: this.state.editorBody
    }
    postNewCampaign(campaignData)
    .then((response)=>{
      this.setState({
        scheduleCampaignResponse: 'New campaign scheduled for ' + Moment(this.state.scheduleDate).format("MM/DD/YYYY h:mm:ss a")
      }, ()=> {
        this.updateScheduledCampaigns()
      })
    })
    .catch((err)=>{
      this.setErrorMessage(err)
      this.setState({
        scheduleCampaignResponse: err.message
      }, ()=> {
        this.updateScheduledCampaigns()
      })
    })
  }

  updateScheduleDate = (scheduleDate) => {
    if(Date.parse(scheduleDate)){
      this.setState({
        scheduleDate: Date.parse(scheduleDate)
      })
    }
  }

  deleteCampaign = (id) => {
    deleteCampaign(id)
    .then((response) => {
      this.updateScheduledCampaigns()
    })
    .catch((err) => {
      this.setErrorMessage(err)
    })
  }

  updateEmailTo = (event) => {
    this.setState({
      emailTo: event.currentTarget.value
    })
  }

  setSelectedArticles = (articles) => {
    this.setState({
      selectedArticles: articles
    },function(){
      let body = ''
      for (var article in articles) {
        if(articles[article]){
          body += '<h1>' + articles[article].title + '</h1>'
          body += '<img src="' + articles[article].item_image + '" style="width: 100%; height: auto; max-width: 580px;" width="580" />'
          body += articles[article].introtext
          body += articles[article].fulltext
          body += '<blockquote><p>BREAK</p></blockquote>'
        }
      }
      this.setState({
        editorBody: body
      })
    })
  }

  getSelectedArticle = (article) => {
    var match = this.state.articles.find( function(e) {
      return e.id === parseInt(article)
    })
    return match
  }

  updateHTMLBody = (editorBody) => {
    this.setState({
      editorBody: editorBody
    })
  }

  handleUpdate = (event) => {
    var updateObject = {
      [event.currentTarget.name]: event.currentTarget.value,
    }
    this.setState(updateObject)
  }

  render(){
    let emailPost = {
      'subject': 'Pulse Update | ' + Moment(this.state.scheduleDate).format("MM/DD/YYYY"),
      'toAddress': this.state.emailTo,
      'sendDate':  Date.parse(Moment(this.state.scheduleDate).format("MM/DD/YYYY h:mm:ss a")),
      'body': this.state.editorBody,
      'template': this.state.selectedTemplate
    }
    let campaignSubject = 'Pulse Update | ' + Moment(this.state.scheduleDate).format("MM/DD/YYYY")
    let errorMessage = null
    if(this.state.errorMessage){
      errorMessage = <div className={styles['form__error']}>{this.state.errorMessage}</div>
    }
    return (
      <Layout>
      <View title="Pulse Email Digest Campaign">
        {errorMessage}
        <MultiStepForm
          formValid={true}
        >
          <FormTab label="SELECT" key="1" nextLabel="EDIT">
            <Form
              handleUpdate={e => this.handleUpdate(e)}
              selectedTemplate={this.state.selectedTemplate}
            />
            <AvailableArticles
              articles={this.state.articles}
              fromDate={this.state.articlesFrom}
              selectable={true}
              setSelected={this.setSelectedArticles}
              selected={this.state.selectedArticles}
            />
          </FormTab>
          <FormTab label="EDIT" key="2" nextLabel="PREVIEW" prevLabel="BACK">
            <DigestEditor
              body={this.state.editorBody}
              onUpdate={this.updateHTMLBody}
            />
          </FormTab>
          <FormTab label="PREVIEW" key="3" prevLabel="BACK" nextLabel="SCHEDULE">
            <EmailPreview
              pulse={true}
              email={emailPost}
              setErrorMessage={this.setErrorMessage}
            />
            <DigestTest
              handleUpdate={this.updateEmailTo}
              to={this.state.emailTo}
            />
            <button onClick={this.sendTestEmail} className={styles['form__button']}>Send Test Email to {this.state.emailTo}</button>
            <div>{this.state.testEmailResponse}</div>
          </FormTab>
          <FormTab label="SCHEDULE" key="4" prevLabel="BACK" nextLabel="" resetLabel="Begin Another Campaign">
            <div className="row">
              <div className="col-md-4 col-xs-12">
               <h2>Schedule a New Campaign</h2>
                <ScheduleForm
                  scheduleDate={this.state.scheduleDate}
                  handleSubmit={this.submitScheduleDate}
                  handleUpdate={this.updateScheduleDate}
                  subject={campaignSubject}
                />
                <div>{this.state.scheduleCampaignResponse}</div>
              </div>
              <div className="col-md-1 col-xs-12">
              </div>
              <div className="col-md-7 col-xs-12">
               <CampaignsList
               scheduledCampaigns={this.state.scheduledCampaigns}
               deleteCampaign={this.deleteCampaign}
               />
              </div>
           </div>
          </FormTab>
        </MultiStepForm>
      </View>
      </Layout>
    )
  }
}

export default PulseEmail

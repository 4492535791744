import React, { Component } from 'react'
import PropTypes from 'prop-types'
// import Tab from './Tab'
import FormTab from './FormTab'
import * as styles from './multistepform.module.css'

class MultiStepForm extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Array).isRequired,
  }

  constructor(props) {
    super(props)

    this.state = {
      activeTabIndex: 0,
    }
  }

  firstStep = button => {
    this.setState({activeTabIndex: 0})
  }

  nextStep = button => {
    if(this.state.activeTabIndex < this.props.children.length){
      let newIndex = this.state.activeTabIndex+1
      this.setState({activeTabIndex: newIndex},function(){
        if(this.props.children[this.state.activeTabIndex].props.nextAction){
          this.props.children[this.state.activeTabIndex].props.nextAction()
        }
      })
    }
  }

  prevStep = button => {
    if(this.state.activeTabIndex > 0){
      let newIndex = this.state.activeTabIndex-1
      this.setState({activeTabIndex: newIndex})
    }
  }

  render() {
    const {
      nextStep,
      prevStep,
      props: { children },
      state: { activeTabIndex },
    } = this

    let tabWidth = {}
    if(children.length > 3){
      tabWidth = {
        width: (100 / children.length) + '%'
      }
    }
    return (
      <div className="tabs">
      <ol className={styles['progressBar']}>
        {children.map((child, index) => {
          const { label } = child.props
          return (
            <li key={index} className={(index <= activeTabIndex ? styles['active'] : '')} style={tabWidth} >
              {label}
            </li>
          )
        })}
      </ol>
        <div className="tab-content">
          <FormTab
            content={children[activeTabIndex].props.children}
            nextStep={nextStep}
            nextLabel={children[activeTabIndex].props.nextLabel}
            prevStep={prevStep}
            prevLabel={children[activeTabIndex].props.prevLabel}
            formValid={this.props.formValid}
            resetLabel={children[activeTabIndex].props.resetLabel}
            resetForm={this.firstStep}
            lastStep={children[activeTabIndex].props.lastStep}
            lastStepLabel={children[activeTabIndex].props.lastStepLabel}
          />
        </div>
      </div>
    )
  }
}

export default MultiStepForm

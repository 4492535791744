import React, { Component } from 'react'
import { getEmailPreview } from '../../utils/email'
import { getPulseEmailPreview } from '../../utils/pulse'
import * as style from './email.preview.module.css'
import WaitLoader from '../WaitLoader'

export default class EmailPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emailBody: '<p></p>',
      random: 0,
      imageUrl: '',
      title: '',
      url: '',
      loading: true
    }

  }

  componentDidMount() {
    this.getPreview()
  }

  getPreview() {
    console.log(this.props)
    var updateEmailBody = function(text) {
      this.setState({ emailBody: text, loading: false })
      this.setState({ random: this.state.random + 1 })
    }.bind(this)

    if(this.props.pulse){
      let emailPost = this.props.email
      emailPost.pages = this.props.pages
      getPulseEmailPreview(this.props.email)
      .then((response)=>{
        return updateEmailBody(''+response.previewBody)
      })
      .catch((err)=>{
        if(this.props.setErrorMessage){
          this.props.setErrorMessage(err)
        }
        return updateEmailBody('<div>Error</div>')
      })
    }else{
      getEmailPreview(
        this.props.to,
        this.props.subject,
        this.props.userMessage,
        this.props.domain,
        this.props.pages,
        this.props.showUserImage,
        this.props.overrideTemplate,
        function(err, data) {
          if (err) {
            console.log(err)
            if(this.props.setErrorMessage){
              this.props.setErrorMessage(err)
            }
            updateEmailBody(
              '<div>Error</div>'
            )
          }else{
            if(data.errorMessage){
              updateEmailBody(
                '<div>'+data.errorMessage+'</div>'
              )
            }else{
              updateEmailBody('' + data)
            }
          }
        }.bind(this)
      )
    }
  }

  render() {
    return (
      <>
      <WaitLoader loading={this.state.loading} />
      <iframe
        className={style['previewIframe']}
        title="Email Preview"
        key={this.state.random}
        id="myiframe"
        srcDoc={this.state.emailBody}
        name="emailpreview"
      />
      </>
    );
  }
}

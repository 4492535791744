import React from 'react'
import * as styles from './form.module.css'

const PulseDigestTest = (({ handleUpdate, to, history }) => (
  <form
  className={styles.form}
  >
  <label className={styles[`form__label`]}>
    Email
    <input
      className={styles['form__input']}
      name="to"
      value={to}
      onChange={handleUpdate}
    />
  </label>
  </form>
))

export default PulseDigestTest
